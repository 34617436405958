import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"

// Icons
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaMapMarker } from 'react-icons/fa';


const NavbarMain = ({ siteTitle }, { pageInfo },) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar" className="bg-primary" collapseOnSelect>
        {/* <Container> */}
        <Link to="/" className="">
          <Navbar.Brand as="span" className="text-uppercase">
            <span className="font-weight-bold">Big Boys</span> <span className="font-weight-normal">Collision</span>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto text-uppercase" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/#section-location" className="">
              <Nav.Link as="span" eventKey="#section-location" className="d-none">
                <FaMapMarker />
              </Nav.Link>
            </Link>
            <a 
              href="tel:5128786798"
              target="_blank"
              className="nav-link font-weight-bold text-center"
            >
              (512) 878-6798
            </a>
            <Link to="/#section-location">
              <Nav.Link as="span" eventKey="#section-location" className="d-none">
                <FaMapMarkerAlt />
              </Nav.Link>
            </Link>
          </Nav>
          <nav class="d-block d-lg-none text-center">
            <a href="https://goo.gl/maps/yE3mC9hwVtXAXhBKA" target="_blank" rel="noreferrer">
              5040 SH 123 Bld 400
              <br />
              San Marcos, TX 78666
            </a>
            <br></br>
            <a
              href="#"
              target="_blank"
              className="d-none large"
              title="Email"
            >
              <FaEnvelope />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://www.facebook.com/bigboyscollision"
              target="_blank"
              className="large"
              title="Facebook"
            >
              <FaFacebook />
            </a>
            <br></br>
          </nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default NavbarMain
