/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Navbar from "./navBar"

// Icons
import { FaFacebook } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Navbar pageInfo={pageInfo} siteTitle={data.site.siteMetadata.title} />
        <main className="layout--main">{children}</main>
        <footer class="">
          <Container fluid>
            <Row className="align-items-center py-2 text-center">
              <Col md="4">
                <a href="tel:5128786798" target="_blank">
                  (512) 878-6798
                </a>
              </Col>
              <Col md="4" className="">
                <a
                  href="https://goo.gl/maps/yE3mC9hwVtXAXhBKA"
                  target="_blank"
                  rel="noreferrer"
                >
                  5040 SH 123 Bld 400
                  <br />
                  San Marcos, TX 78666
                </a>
              </Col>
              <Col md="4">
                <a
                  href="mailto:chuy.bigboys@yahoo.com"
                  target="_blank"
                  className="large mr-sm"
                  title="Email"
                >
                  <FaEnvelope />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://www.facebook.com/bigboyscollision"
                  target="_blank"
                  className="large"
                  title="Facebook"
                >
                  <FaFacebook />
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )}
  />
)

export default Layout
